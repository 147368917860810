<template>
    <div>
        <table width="100%" class="vs-table vs-table--tbody-table ">
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Warehouse Code
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.warehouse.code + ' - ' + this.form.warehouse.name }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Shipment Number
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.shipment_number }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Supplier
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.supplier.code + ' - ' + this.form.supplier.name }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Delivery Note Number
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.delivery_order_number }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Purchase Data
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.code }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Delivery Note Date
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + formatDate(this.form.dn_date) }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Receive Date
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + formatDate(this.form.date) }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Notes
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.notes }}
                </td>
            </tr>
        </table>

        <table width="100%" class="vs-table vs-table--tbody-table print">
            <thead class="vs-table--thead">
                <tr>
                    <th colspan="1" style="text-align: center;">Supplier</th>
                    <th colspan="2" style="text-align: center;">Internal</th>
                    <th colspan="2" style="text-align: center;">GR/Conversion</th>
                    <th colspan="4" style="text-align: center;">Batch/ED Data</th>
                    <th colspan="2" style="text-align: center;">Put Away</th>
                </tr>
                <tr>
                    <th>
                        <div>SKU Code</div>
                    </th>
                    <th>
                        <div>SKU Code</div>
                    </th>
                    <th>
                        <div>SKU Description</div>
                    </th>
                    <th>
                        <div>HU</div>
                    </th>
                    <th>
                        <div>Qty</div>
                    </th>
                    <th>
                        <div>Qty</div>
                    </th>
                    <th>
                        <div>Batch #</div>
                    </th>
                    <th>
                        <div>Expired Date</div>
                    </th>
                    <th>
                        <div>Check Status</div>
                    </th>
                    <th>
                        <div>Qty</div>
                    </th>
                    <th>
                        <div>Suggested Warehouse</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(line, idx) in form.putaway_dto" :key="idx">
                    <td :rowspan="line.lineCount" class="td vs-table--td" style="padding: 5px"
                        v-if="idx == 0 || form.putaway_dto[idx].purchase_order_line_id !== form.putaway_dto[idx - 1].purchase_order_line_id">
                        {{ line.supplier_sku_code }}
                    </td>
                    <td :rowspan="line.lineCount" class="td vs-table--td" style="padding: 5px"
                        v-if="idx == 0 || form.putaway_dto[idx].purchase_order_line_id !== form.putaway_dto[idx - 1].purchase_order_line_id">
                        {{ line.internal_sku_code }}
                    </td>
                    <td :rowspan="line.lineCount" class="td vs-table--td" style="padding: 5px"
                        v-if="idx == 0 || form.putaway_dto[idx].purchase_order_line_id !== form.putaway_dto[idx - 1].purchase_order_line_id">
                        {{ line.item_name }}
                    </td>
                    <td :rowspan="line.convCount" class="td vs-table--td" style="padding: 5px"
                        v-if="idx == 0 || form.putaway_dto[idx].conversion_key !== form.putaway_dto[idx - 1].conversion_key">
                        {{ line.hu_name }}
                    </td>
                    <td :rowspan="line.convCount" class="td vs-table--td" style="padding: 5px"
                        v-if="idx == 0 || form.putaway_dto[idx].conversion_key !== form.putaway_dto[idx - 1].conversion_key">
                        {{ line.conversion_quantity }}
                    </td>
                    <td :rowspan="line.checkCount" class="td vs-table--td" style="padding: 5px"
                        v-if="idx == 0 || form.putaway_dto[idx].purchase_receive_line_id !== form.putaway_dto[idx - 1].purchase_receive_line_id">
                        {{ line.check_quantity }}
                    </td>
                    <td :rowspan="line.checkCount" class="td vs-table--td" style="padding: 5px"
                        v-if="idx == 0 || form.putaway_dto[idx].purchase_receive_line_id !== form.putaway_dto[idx - 1].purchase_receive_line_id">
                        {{ line.batch }}
                    </td>
                    <td :rowspan="line.checkCount" class="td vs-table--td" style="padding: 5px"
                        v-if="idx == 0 || form.putaway_dto[idx].purchase_receive_line_id !== form.putaway_dto[idx - 1].purchase_receive_line_id">
                        <span v-if="line.expired_date">{{ formatDate(line.expired_date) }}</span>
                    </td>
                    <td :rowspan="line.checkCount" class="td vs-table--td" style="padding: 5px"
                        v-if="idx == 0 || form.putaway_dto[idx].purchase_receive_line_id !== form.putaway_dto[idx - 1].purchase_receive_line_id">
                        {{ checkOptions[line.is_damaged].name }}
                    </td>
                    <td class="td vs-table--td">
                        <template v-if="line.warehouse_area">
                            <div>{{ line.quantity }}</div>
                        </template>
                    </td>
                    <td class="td vs-table--td">
                        <template v-if="line.warehouse_area">
                            <div>{{ line.warehouse_area.name }}</div>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="flex justify-center w-full pt-6 print-button">
            <vs-button style="margin:5px" color="primary" @click="handlePrint()">Print</vs-button>
            <vs-button style="margin:5px" color="primary" @click="handleBack()">Exit</vs-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        this.initData();
    },
    data() {
        return {
            dialog: {
                save: false,
            },
            checkOptions: [{
                value: 0,
                name: "Passed",
            }, {
                value: 1,
                name: "Damaged",
            }],
            id: null,
            attachment: null,
            form: {
                id: null,
                purchase_order_id: null,
                warehouse: null,
                supplier: null,
                status: "0",
                dn_date: moment().toDate(),
                purchaseData: null,
                code: null,
                date: null,
                notes: null,
                delivery_order_number: null,
                purchase_date: null,
                shipment_number: null,
                putaway_dto: null,
            },
        }
    },
    methods: {
        async handlePrint() {
            await window.print();
        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName,
            });
            this.$vs.loading.close();
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/put-away/${this.id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.form = resp.data;
                        this.form.supplier = {
                            code: resp.data.supplier_code,
                            name: resp.data.supplier_name,
                        };

                        this.form.putaway_dto.forEach((line) => {
                            line.lineCount = 0;
                            line.convCount = 0;
                            line.checkCount=0;

                            this.form.putaway_dto.forEach((item) => {
                                if (line.purchase_order_line_id == item.purchase_order_line_id) {
                                    line.lineCount++;
                                }
                                if (line.conversion_key == item.conversion_key) {
                                    line.convCount++;
                                }
                                if (line.purchase_receive_line_id == item.purchase_receive_line_id) {
                                    line.checkCount++;
                                }
                            })
                        })
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
}
</script>
<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
        background: none !important;
    }

    * {
        background: none !important;
    }

    header,
    footer,
    .print-button,
    #content-area * :not(.content-wrapper *) {
        display: none !important;
        visibility: hidden !important;
    }

    .router-view,
    .router-content {
        padding: 0 !important;
        margin: 0 !important;
    }
}

table.print {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

table.print td,
table.print th {
    border: 1px solid #abab !important;
    text-align: left;
    padding: 8px;
}

table.print td>table * {
    border: 0px !important;
}
</style>
